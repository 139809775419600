import React, { useState, useRef } from 'react'
import './FeedCard.scss'
import Caret from '../../images/icons/Caret'
import { useSpring, animated } from 'react-spring'
import formatDateToDayMonth from '../../utils/formatDateToDayMonth'
import { Img } from 'gatsby-image'
import ReactHtmlParser from 'react-html-parser'
import { useMediaQuery } from 'react-responsive'

export default ({ data, twitter }) => {
	const { titles, textContent, date, image } = data
	const details =
		typeof textContent !== 'undefined'
			? titles.length > 1 && textContent.length > 1
			: false
	const detailRef = useRef()
	const [detailsOpen, setDetailsOpen] = useState(false)

	function toggleDetails() {
		setDetailsOpen((value) => !value)
	}

	const isMobile = useMediaQuery({
		query: '(max-width: 48rem)',
	})

	const transition = isMobile
		? useSpring(
				{
					maxHeight: detailsOpen
						? '100vh'
						: twitter
						? '100%'
						: '15vh',
				},
				{
					mass: 2,
					tension: 350,
					friction: 30,
				},
		  )
		: useSpring(
				{
					maxHeight: detailsOpen
						? '100vh'
						: twitter
						? '100%'
						: '15vh',
				},
				{
					mass: 2,
					tension: 350,
					friction: 30,
				},
		  )
	const formattedDate = formatDateToDayMonth(date)

	return (
		<animated.li
			className={`feed-card${detailsOpen ? ' active' : ''}`}
			onClick={details ? toggleDetails : null}
			tabIndex={details ? '0' : null}
			aria-label={'Klik voor meer informatie'}
			ref={details ? detailRef : null}
			style={transition}
		>
			{date ? <time dateTime={date}>{formattedDate}</time> : null}
			{details ? <Caret /> : null}
			<div
				className={`feed-card__container ${
					textContent ? '' : ' single-title'
				}`}
			>
				{image && <Img fluid={image.localFile.childImageSharp.fluid} />}
				{details ? (
					textContent.map((text, i) => {
						return (
							<React.Fragment key={`${text}-${i}`}>
								{titles[i] ? (
									<h3 className={'feed-card__title'}>
										{titles[i]}
									</h3>
								) : null}
								<span className={'feed-card__text'}>
									{ReactHtmlParser(text)}
								</span>
							</React.Fragment>
						)
					})
				) : (
					<React.Fragment>
						{titles.length
							? titles.map((title, i) => (
									<h3
										className={`feed-card__title`}
										key={`${title}-${i}`}
									>
										{title}
									</h3>
							  ))
							: null}
						{typeof textContent !== 'undefined' &&
						textContent.length
							? textContent.map((text, i) => (
									<span
										className={'feed-card__text'}
										key={`${text}-${i}`}
									>
										{ReactHtmlParser(text)}
									</span>
							  ))
							: null}
					</React.Fragment>
				)}
			</div>
		</animated.li>
	)
}
