import React, { useContext } from 'react'
import './FooterEmailSignUp.scss'
import LogoNoText from '../../images/icons/Logo/LogoNoText'
import Button from '../Button/Button'
import { HeaderContext } from '../Header/Header'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default ({ props }) => {
	const HeaderCTX = useContext(HeaderContext)
	return (
		<article className="email-sign-up">
			<div className={'email-sign-up--container'}>
				<p>Blijf up to date met leuke en informatieve updates!</p>
				<LogoNoText
					color={
						!HeaderCTX.winterTime
							? 'rgba(234, 134, 45, 1)'
							: '#005394'
					}
				/>
				<div className={'email-sign-up--form'}>
					<form
						action="https://api.elasticemail.com/contact/add?version=2"
						data-id="ROXRcSWXci"
						id="ewf_subscriptionForm_ROXRcSWXci"
						class="EWF__form Inline"
						method="POST"
						onSubmit={() => {
							trackCustomEvent({
								// string - required - The object that was interacted with (e.g.video)
								category: 'email',
								// string - required - Type of interaction (e.g. 'play')
								action: 'signup',
							})
						}}
					>
						<input
							type="hidden"
							name="publicaccountid"
							value="abc4fe4a-f99e-45a0-b386-267192582ec4"
						/>
						<input type="hidden" name="returnUrl" value="" />
						<input
							type="hidden"
							name="activationReturnUrl"
							value=""
						/>
						<input type="hidden" name="alreadyactiveurl" value="" />
						<input
							type="hidden"
							name="activationTemplate"
							value=""
						/>
						<input type="hidden" name="source" value="WebForm" />
						<input
							type="hidden"
							name="verifyemail"
							value="false"
							id="ewf_verifyEmails"
						/>
						<input
							type="hidden"
							id="ewf_captcha"
							name="captcha"
							value="false"
						/>
						<input type="hidden" name="notifyEmail" value="" />
						<input
							type="checkbox"
							style={{ display: 'none' }}
							name="publiclistid"
							id="dsbQEaCu"
							value="a33f8a04-1427-4f46-8493-07d8933f3f06"
							checked="checked"
						/>
						<label htmlFor="field_firstname">Naam</label>
						<input
							type="text"
							id={'field_firstname'}
							placeholder={'Uw naam'}
							name="field_firstname"
						/>
						<label htmlFor="email">E-mail</label>
						<input
							type="email"
							id={'email'}
							placeholder={'info@inbalansalkmaar.nl'}
							name="email"
						/>
						<Button
							text={'Aanmelden'}
							type={'submit'}
							name="submit"
						/>
					</form>
				</div>
			</div>
			<svg
				className={'background'}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1440 349"
				preserveAspectRatio="none"
			>
				<defs>
					<linearGradient
						id="emailsignup-gradientBg"
						x1="-32.33%"
						x2="58.35%"
						y1="45.27%"
						y2="50.32%"
					>
						<stop
							offset="0%"
							stopColor={
								!HeaderCTX.winterTime ? '#008CC6' : '#f6bb58'
							}
						/>
						<stop
							offset="100%"
							stopColor={
								!HeaderCTX.winterTime ? '#005495' : '#ea862d'
							}
						/>
						<stop
							offset="100%"
							stopColor={
								!HeaderCTX.winterTime ? '#005394' : '#ea862d'
							}
						/>
					</linearGradient>
				</defs>
				<path
					fill="url(#emailsignup-gradientBg)"
					fillRule="evenodd"
					d="M.9 56.55c207.16-26.7 687.8-31.86 1441.88-15.5l-.82 338.56C1144.13 352.97 663.63 346.87.46 361.29L.9 56.55z"
					transform="translate(-1 -31)"
				/>
			</svg>
		</article>
	)
}
