import React, { useState, useRef, useContext } from 'react'
import './SignUpFormModal.scss'
import { useSpring, animated } from 'react-spring'
import HamburgerMenu from '../../images/icons/HamburgerMenu/HamburgerMenu'
import { useKeyPress } from '../../utils/useKeyPress'

export const SignUpFormContext = React.createContext()

// create the provider
export const SignUpFormContextProvider = (props) => {
	const [modalOpenState, setModalOpenState] = useState(false)

	return (
		<SignUpFormContext.Provider
			value={{
				isOpen: modalOpenState,
				toggleModal: () => {
					setModalOpenState(!modalOpenState)
					document.body.classList.toggle('has-overlay')
				},
				stateChangeHandler: (newState) => {
					setModalOpenState(newState)
					document.body.classList.remove('has-overlay')
				},
			}}
		>
			{props.children}
		</SignUpFormContext.Provider>
	)
}

const config = {
	mass: 2,
	tension: 350,
	friction: 30,
}

export default ({ children }) => {
	const signUpFormOpen = useContext(SignUpFormContext)
	useKeyPress('Escape', () => {
		closeSignUpForm('close')
		document.documentElement.classList.remove('has-overlay')
	})
	const drawer = useRef()

	const modalTransition = useSpring({
		transform: `translateX(${signUpFormOpen.isOpen ? '0%' : '101%'})`,
		config: config,
	})

	function closeSignUpForm(close) {
		if (close === 'close') signUpFormOpen.stateChangeHandler(false)
	}

	return (
		<React.Fragment>
			<section className="sign-up-form__modal">
				<animated.div
					aria-expanded={signUpFormOpen.isOpen}
					className="sign-up-form__modal--drawer"
					ref={drawer}
					style={modalTransition}
				>
					<button
						className="sign-up-form__modal--button"
						onClick={() => signUpFormOpen.stateChangeHandler(false)}
					>
						<HamburgerMenu addedClass={' open'} />
					</button>
					{children}
				</animated.div>
			</section>
			<div
				aria-hidden={!signUpFormOpen.isOpen}
				className={`gradient${signUpFormOpen.isOpen ? ' active' : ''}`}
				onClick={() => signUpFormOpen.stateChangeHandler(false)}
			>
				{''}
			</div>
		</React.Fragment>
	)
}
