import React, { useContext, useState } from 'react'
import './Footer.scss'
import FooterLinkList from './FooterLinkList'
import Logo from '../../images/icons/Logo/Logo'
import FooterEmailSignUp from '../FooterEmailSignUp/FooterEmailSignUp'
import { useStaticQuery, graphql } from 'gatsby'
import { encodeToReadableUrl } from '../../utils/encodeToReadableUrl'
import { HeaderContext } from '../Header/Header'
import { contactDetails } from '../../utils/contact'
import { Link } from 'gatsby'

const Footer = () => {
	const HeaderCTX = useContext(HeaderContext)
	const { allWordpressWpCursus, allWordpressWpReis } = useStaticQuery(
		graphql`
			query {
				allWordpressWpCursus(limit: 50) {
					nodes {
						acf {
							title_course
							subject
							start_date
							end_date
							price
							category
							customId
						}
						wordpress_id
						link
					}
				}
				allWordpressWpReis(limit: 10) {
					nodes {
						acf {
							reis_blok {
								reis_title
							}
							reis_id
						}
						wordpress_id
						link
					}
				}
			}
		`,
	)

	const courseLinks = allWordpressWpCursus.nodes
		.map((course) => {
			const category = course.acf.category.toString()

			if (
				HeaderCTX.winterTime &&
				course.acf.subject === 'Schaatsen' &&
				[
					'Volwassenen',
					'Midjeugd(13 - 16)',
					'Jeugd(6 - 12)',
				].some((item) => category.includes(item))
			) {
				return {
					name: course.acf.title_course,
					route: `/winter/cursus/${encodeToReadableUrl(course.link)}${
						course.wordpress_id
					}/`,
				}
			}
			if (
				!HeaderCTX.winterTime &&
				course.acf.subject !== 'Schaatsen' &&
				[
					'Volwassenen',
					'Midjeugd(13 - 16)',
					'Jeugd(6 - 12)',
				].some((item) => category.includes(item))
			) {
				return {
					name: course.acf.title_course,
					route: `/zomer/cursus/${encodeToReadableUrl(course.link)}${
						course.wordpress_id
					}/`,
				}
			}

			return
		})
		.filter((value) => typeof value !== 'undefined')

	const tripLinks = allWordpressWpReis.nodes.map((trip) => ({
		name: trip.acf.reis_blok.reis_title,
		route: `/vakantie/${encodeToReadableUrl(trip.link)}${
			trip.wordpress_id
		}/`,
	}))

	const clinicsLinks = allWordpressWpCursus.nodes
		.map((course) => {
			const category = course.acf.category.toString()

			// !HeaderCTX.winterTime &&

			if (
				HeaderCTX.winterTime &&
				course.acf.subject === 'Schaatsen' &&
				category.includes('Overig')
			) {
				return {
					name: course.acf.title_course,
					route: `/winter/cursus/${encodeToReadableUrl(course.link)}${
						course.wordpress_id
					}/`,
				}
			}
			if (
				!HeaderCTX.winterTime &&
				course.acf.subject !== 'Schaatsen' &&
				category.includes('Overig')
			) {
				return {
					name: course.acf.title_course,
					route: `/zomer/cursus/${encodeToReadableUrl(course.link)}${
						course.wordpress_id
					}/`,
				}
			}

			return undefined
		})
		.filter((value) => typeof value !== 'undefined')

	const currentYear = new Date().getFullYear()

	const [visibleModal, setVisibleModal] = useState(false)
	const [visiblePrivacyModal, setVisiblePrivacyModal] = useState(false)

	return (
		<React.Fragment>
			<FooterEmailSignUp />
			<footer className={'footer'} id={'contact'}>
				<div className="footer__logo">
					<Logo secondary={true} />
				</div>
				<figure>
					<a
						title="InBalans Facebook"
						aria-label="InBalans Facebook"
						href="https://facebook.com/inbalans.alkmaar/"
						rel="noopener noreferrer"
						target="_blank"
					>
						<svg
							viewBox="0 0 55 52"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g fill="#FBF9F3" fillRule="nonzero">
								<path d="M31.928 51.572zM32.65 51.483c-.168.094-.334.18-.5.268.166-.088.332-.174.5-.268zM30.625 51.741zM31.266 51.68zM33.435 51.343zM35.346 50.907c-.166.104-.333.201-.5.303.167-.102.334-.2.5-.303zM34.764 51.052c-.166.104-.333.209-.5.309.167-.1.334-.203.5-.309zM34.014 51.226c-.165.097-.333.189-.5.283.167-.094.335-.186.5-.283zM29.875 51.817zM54.961 25.955C54.961 11.623 42.664 0 27.5 0 12.336 0 .039 11.623.039 25.955c0 14.332 12.297 25.954 27.461 25.954.161 0 .322-.003.483-.005V31.699h-5.9V25.2h5.9v-4.782c0-5.546 3.582-8.565 8.816-8.565 2.507 0 4.662.177 5.29.256v5.796h-3.61c-2.847 0-3.399 1.28-3.399 3.156v4.14h6.81l-.888 6.498H35.08v19.208c11.478-3.11 19.881-13.105 19.881-24.952zM29.314 51.852zM28.483 51.893z"></path>
							</g>
						</svg>{' '}
					</a>
					<a
						title="InBalans Twitter"
						aria-label="InBalans Twitter"
						href="https://twitter.com/inbalansalkmaar"
						rel="noopener noreferrer"
						target="_blank"
					>
						<svg
							viewBox="0 0 55 53"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M27.461.687C12.297.687 0 12.2 0 26.394s12.297 25.707 27.461 25.707c15.164 0 27.461-11.512 27.461-25.707S42.625.687 27.462.687zM40 20.731c.012.253.018.507.018.762 0 7.797-6.34 16.788-17.934 16.788-3.56 0-6.872-.977-9.662-2.65.494.054.996.081 1.504.081 2.953 0 5.67-.943 7.828-2.525-2.759-.048-5.085-1.754-5.888-4.099a6.688 6.688 0 0 0 2.847-.1c-2.884-.541-5.057-2.927-5.057-5.785v-.075c.85.442 1.821.708 2.856.738-1.693-1.057-2.805-2.864-2.805-4.911a5.6 5.6 0 0 1 .853-2.966c3.109 3.57 7.754 5.918 12.992 6.165a5.536 5.536 0 0 1-.163-1.346c0-3.257 2.823-5.9 6.303-5.9 1.814 0 3.451.718 4.601 1.865a13.177 13.177 0 0 0 4.003-1.432c-.472 1.377-1.47 2.533-2.772 3.263 1.275-.142 2.49-.459 3.62-.929A12.387 12.387 0 0 1 40 20.731z"
								fill="#FBF9F3"
								fillRule="nonzero"
							></path>
						</svg>{' '}
					</a>
					<a
						title="InBalans Instagram"
						aria-label="InBalans Instagram"
						href="https://www.instagram.com/inbalansalkmaar/"
						rel="noopener noreferrer"
						target="_blank"
					>
						<svg
							viewBox="0 0 55 55"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g fill="#FBF9F3" fillRule="nonzero">
								<path d="M32.795 27.5a5.26 5.26 0 0 1-5.256 5.264 5.26 5.26 0 0 1-5.256-5.264 5.26 5.26 0 0 1 5.256-5.264 5.26 5.26 0 0 1 5.256 5.264z"></path>
								<path d="M39.831 18.187a4.893 4.893 0 0 0-1.18-1.816 4.882 4.882 0 0 0-1.812-1.18c-.556-.217-1.39-.474-2.928-.544-1.663-.076-2.162-.093-6.372-.093s-4.71.016-6.372.092c-1.538.07-2.373.328-2.928.544a4.878 4.878 0 0 0-1.813 1.181 4.896 4.896 0 0 0-1.18 1.816c-.216.556-.473 1.393-.543 2.932-.076 1.665-.092 2.165-.092 6.381 0 4.217.016 4.716.092 6.382.07 1.54.327 2.375.543 2.931a4.895 4.895 0 0 0 1.18 1.816 4.882 4.882 0 0 0 1.813 1.181c.555.217 1.39.474 2.928.544 1.662.076 2.16.092 6.371.092 4.212 0 4.71-.016 6.373-.092 1.537-.07 2.372-.327 2.928-.544a5.226 5.226 0 0 0 2.992-2.997c.216-.556.473-1.392.543-2.931.076-1.666.092-2.165.092-6.382 0-4.216-.016-4.716-.092-6.38-.07-1.54-.326-2.377-.543-2.933zM27.54 35.609c-4.472 0-8.098-3.63-8.098-8.109s3.626-8.109 8.098-8.109c4.472 0 8.097 3.63 8.097 8.109s-3.625 8.109-8.097 8.109zm8.417-14.643a1.894 1.894 0 0 1-1.892-1.895c0-1.047.847-1.895 1.892-1.895s1.893.848 1.893 1.895a1.894 1.894 0 0 1-1.893 1.895z"></path>
								<path d="M27.539 0C12.375 0 .078 12.314.078 27.5S12.375 55 27.538 55C42.704 55 55 42.686 55 27.5S42.703 0 27.539 0zm15.673 34.01c-.076 1.681-.343 2.829-.732 3.834a8.069 8.069 0 0 1-4.612 4.618c-1.003.39-2.15.657-3.828.734-1.681.077-2.218.095-6.5.095-4.284 0-4.82-.018-6.502-.095-1.678-.077-2.825-.344-3.828-.734a7.724 7.724 0 0 1-2.793-1.822 7.74 7.74 0 0 1-1.818-2.796c-.39-1.005-.657-2.153-.733-3.833-.078-1.685-.096-2.223-.096-6.511s.018-4.826.095-6.51c.077-1.681.343-2.829.733-3.834a7.737 7.737 0 0 1 1.82-2.796 7.723 7.723 0 0 1 2.792-1.822c1.003-.39 2.15-.657 3.828-.734 1.681-.077 2.218-.095 6.5-.095 4.283 0 4.82.018 6.502.096 1.679.076 2.825.343 3.828.733a7.729 7.729 0 0 1 2.793 1.822 7.738 7.738 0 0 1 1.819 2.796c.39 1.005.656 2.153.733 3.834.077 1.684.095 2.222.095 6.51s-.018 4.826-.096 6.51z"></path>
							</g>
						</svg>{' '}
					</a>
				</figure>

				<nav className={'links'}>
					<FooterLinkList title={'Cursussen'} links={courseLinks} />
					<div>
						<h4 className={'title'}>Reizen</h4>
						<ul>
							{tripLinks.map((link) => (
								<li key={link.name}>
									<Link
										key={link.name}
										className={'link'}
										to={link.route}
									>
										{link.name}
									</Link>
								</li>
							))}
						</ul>
						<h4 className={'title'}>Overig</h4>
						<ul>
							{clinicsLinks.map((link) => (
								<li key={link.name}>
									<Link
										key={link.name}
										className={'link'}
										to={link.route}
									>
										{link.name}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</nav>

				<div className={'contact'}>
					<h4 className={'title'}>Direct contact via</h4>
					<div className={'row'}>
						<a
							className={'contact__link'}
							href={`tel:${contactDetails.phone}`}
						>
							{contactDetails.phoneLabel}
						</a>{' '}
						<a
							className={'contact__link'}
							href={`mailto:${contactDetails.email}`}
							target={'_blank'}
							rel="noopener noreferrer"
						>
							{contactDetails.email}
						</a>{' '}
					</div>
				</div>

				<div className={'contact'}>
					<p>IBAN: NL61 INGB 000 84 500 47</p>
					<p>BIC: INGBNL2A</p>
					<p>BTW nummer: 822 981 919 B01</p>
					<p>K.v.K. Noord West Holland: 508 95 117</p>

					<br />

					<address>
						<p>
							Schaats- en Skeelerschool In Balans b.v.
							<br />
							Terborchlaan 301
							<br />
							1816 MH Alkmaar.
						</p>
					</address>
				</div>

				<div className={'subtle'}>
					<p>
						© Copyright: © 2003-{currentYear} Schaats- en
						Skeelerschool In Balans BV. Alle rechten voorbehouden.
					</p>
					<button onClick={() => setVisibleModal(!visibleModal)}>
						Algemene voorwaarden
					</button>
					<button
						onClick={() =>
							setVisiblePrivacyModal(!visiblePrivacyModal)
						}
					>
						Privacy
					</button>
				</div>

				<div className="made-by">
					<a
						title="Level30Wizards"
						href="https://level30wizards.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						website door: <span>Level30Wizards</span>
					</a>
				</div>
				<article
					className={`modal ${visibleModal ? 'open' : ''}`}
					data-nosnippet
				>
					<button onClick={() => setVisibleModal(!visibleModal)}>
						sluiten
					</button>
					{visibleModal && (
						<>
							<b>Algemene voorwaarden</b>
							<p>
								Er kunnen op geen enkele wijze rechten worden
								ontleend aan, noch aanspraak worden gemaakt op
								de inhoud van deze website. Hoewel bij de
								samenstelling van de inhoud van deze website de
								grootst mogelijke zorgvuldigheid wordt betracht,
								bestaat de mogelijkheid dat bepaalde informatie
								onvolledig is, na verloop van tijd verouderd is
								of niet meer correct is. Schaats- en
								Skeelerschool In Balans b.v. is niet
								aansprakelijk voor de eventuele schade die zou
								kunnen voortvloeien uit het gebruik van gegevens
								van deze website. Schaats- en Skeelerschool In
								Balans b.v. aanvaardt geen aansprakelijkheid
								voor schade ten gevolge van het gebruik van deze
								gegevens of gegevens waarnaar links op deze site
								verwijzen. De gegevens op deze site kunnen
								zonder waarschuwing worden gewijzigd.
							</p>
							<p>
								Schaats- en Skeelerschool In Balans b.v. biedt
								geen garantie voor het foutloos en ononderbroken
								functioneren van de site. Verwijzingen of
								verbindingen naar derden / andere sites en/of
								bronnen die geen eigendom zijn van Schaats- en
								Skeelerschool In Balans b.v. zijn slechts
								opgenomen ter informatie van de gebruiker van de
								site. Schaats- en Skeelerschool In Balans b.v.
								is niet verantwoordelijk voor de beschikbaarheid
								van deze sites of bronnen. Schaats- en
								Skeelerschool In Balans b.v. aanvaardt geen
								enkele aansprakelijkheid met betrekking tot de
								inhoud, advertenties, producten of andere zaken
								op dergelijke sites of bronnen. Schaats- en
								Skeelerschool In Balans b.v. is niet
								aansprakelijk voor enige vorm van schade of
								verlies veroorzaakt door, of in verband met het
								gebruik van of door het afgaan op de inhoud,
								goederen of diensten als aangeboden op
								dergelijke sites of bronnen.
							</p>

							<b>Copyright</b>
							<p>
								Alle rechten voorbehouden. Niets uit deze
								website (zoals bijvoorbeeld informatie, beelden,
								images, e.d.) mag worden gekopieerd dan wel
								opgeslagen in een gegevensbestand, in enige vorm
								of op enige wijze. Hetzij elektronisch,
								digitaal, mechanisch, handmatig, fotografisch of
								op enige andere wijze, zonder overleg met de
								eigenaar van Schaats- en Skeelerschool In Balans
								B.V.
							</p>
							<p>
								Schaats- en Skeelerschool In Balans b.v.
								<br />
								Terborchlaan 301
								<br />
								1816 MH Alkmaar.
							</p>
						</>
					)}
				</article>
				<article
					className={`modal ${visiblePrivacyModal ? 'open' : ''}`}
					data-nosnippet
				>
					<button
						onClick={() =>
							setVisiblePrivacyModal(!visiblePrivacyModal)
						}
					>
						sluiten
					</button>
					{visiblePrivacyModal && (
						<>
							<b>Privacy</b>
							<p>
								Schaats- en Skeelerschool In Balans B.V. gaat
								zeer zorgvuldig om met privacy en
								privacygevoelige gegevens binnen de grenzen van
								de Algemene Verordening Gegevensbescherming
								(AVG). In dit Privacystatement informeren wij u
								over de wijze waarop wij persoonsgegevens
								verwerken, voor welke doeleinden wij
								persoonsgegevens verwerken, hoe u uw
								privacyrechten kunt uitoefenen en andere
								informatie over hoe wij omgaan met
								persoonsgegevens. * Persoonsgegevens zijn
								gegevens die gebruikt kunnen worden om een
								individu te identificeren of die aan een
								individu kunnen worden gekoppeld.
							</p>
							<div>
								Dit Privacystatement is van toepassing op alle
								activiteiten en op alle websites van
								ondernemingen en handelsnamen van: Schaats- en
								Skeelerschool In Balans B.V. Deze
								privacyverklaring omschrijft welke
								persoonsgegevens Schaats- en Skeelerschool In
								Balans B.V. verwerkt en voor welke doeleinden
								deze persoonsgegevens worden gebruikt.
								<b>Deze persoonsgegevens verwerken wij</b>
								<ul>
									<li>geslacht</li>
									<li>voornaam en naam</li>
									<li>geboortedatum</li>
									<li>e-mailadres</li>
									<li>adres</li>
									<li>postcode + woonplaats</li>
									<li>telefoonnummer(s)</li>
									<li>IBAN</li>
									<li>
										medische gegevens (alleen op eigen
										verzoek)
									</li>
								</ul>
								<b>
									Hiervoor verwerken wij uw persoonsgegevens
								</b>
								<ul>
									<li>Servicedoeleinden, zoals:</li>
									<li>
										Het opnemen van contact met u en het
										beantwoorden van vragen die u heeft
										gesteld via administratie en
										inschrijfformulieren.
									</li>
									<li>
										Wanneer u zich inschrijft voor een In
										Balans cursus, clinic, workshop, reis,
										ed. of wanneer u producten en/of
										diensten van In Balans afneemt, om u te
										kunnen informeren over In Balans
										aangelegenheden.
									</li>
									<li>Marketingdoeleinden, zoals:</li>
									<li>
										Promotionele doeleinden. Daaronder valt
										onder andere het uitvoeren van winacties
										en promotiecampagnes, verzenden van
										nieuwsbrieven, tonen van aanbiedingen en
										informatie, sturen van informatieve
										berichten of andere elektronische
										boodschappen op Schaats- en
										Skeelerschool In Balans website(s) en
										daarbuiten via social mediakanalen,
										zoals Facebook, Instagram, Twitter en
										LinkedIn.
									</li>
									<li>
										Het opvolgen van wettelijke
										verplichtingen. Schaats- en
										Skeelerschool In Balans verwerkt uw
										gegevens om eventueel te kunnen voldoen
										aan wettelijke verplichtingen, zoals
										fiscale plichten.
									</li>
									<li>
										Ontwikkel- en analysedoeleinden om onze
										website(s) verder te ontwikkelen en te
										verbeteren, voor statistische analyse en
										marktonderzoek, zoals het meten van
										respons op onze winacties en
										promotiecampagnes en het sturen van
										(anonieme) enquêtes.
									</li>
								</ul>
								<b>
									Hiervoor verwerken wij uw medische gegevens
									(alleen op eigen verzoek)
								</b>
								Voor het eventueel aanpassen van lessen,
								clinics, ed. aan de door u aangegeven medische
								omstandigheden. Voor het eventueel aanpassen van
								maaltijden aan de door u aangegeven medische
								omstandigheden. Uw medische gegevens zouden wij
								kunnen doorgeven aan derden / uitvoerende
								partijen, zodat wij er zeker van kunnen zijn dat
								al uw specifieke wensen adequaad geregeld
								worden.
								<b>E-mail</b>
								Wanneer u uw toestemming heeft gegeven voor het
								verzenden van een nieuwsbrief dan wordt deze
								toegezonden. U kunt uw toestemming altijd
								intrekken door u af te melden voor de
								betreffende nieuwsbrief in de afmeldlink. Uw
								e-mailadres wordt verder gebruikt voor het
								toesturen van informatie over (bijzonderheden
								m.b.t. de lopende) cursussen ed., (nieuwe)
								producten en diensten door In Balans, indien u
								daarvoor toestemming heeft gegeven. De
								toestemming kunt u altijd intrekken door gebruik
								te maken van de afmeldlink in het toegezonden
								e-mailbericht. Ook kunt u hiervoor contact
								opnemen met onze administratie via
								info@inbalansalkmaar.nl of 072 52 00 924.
								<b>
									Website(s) van Schaats- en Skeelerschool In
									Balans
								</b>
								Op de website(s) van Schaats- en Skeelerschool
								In Balans treft u verschillende links aan naar
								andere websites. Wij kunnen echter geen
								verantwoordelijkheid dragen met betrekking tot
								de omgang door die partijen met uw gegevens.
								Lees hiervoor de privacyverklaring, indien
								aanwezig, van de site die u bezoekt.
								<b>Kwaliteit en aansprakelijkheid</b>
								Wanneer u persoonsgegevens verstrekt aan
								Schaats- en Skeelerschool In Balans, bent u zelf
								verantwoordelijk voor de juistheid en het
								actueel houden van persoonsgegevens. Schaats- en
								Skeelerschool In Balans is, behoudens opzet of
								grove schuld, niet aansprakelijk voor
								onjuistheden of gevolgen van activiteiten die
								worden ondernomen op basis van door u verstrekte
								onjuiste of onvolledige informatie.
								<b>
									Hoe lang bewaart Schaats- en Skeelerschool
									In Balans persoonsgegevens / beveiliging van
									uw gegevens
								</b>
								Schaats- en Skeelerschool In Balans zal uw
								gegevens uitsluitend gebruiken voor de in deze
								privacyverklaring genoemde doeleinden en bewaart
								persoonsgegevens niet langer dan noodzakelijk.
								De bewaartermijn voor persoonsgegevens is
								afhankelijk van het doeleinde waarvoor de
								persoonsgegevens worden gebruikt. Zo moeten
								bepaalde persoonsgegevens die voor fiscale
								doeleinden worden verwerkt verplicht zeven jaar
								worden bewaard. Nadat uw persoonsgegevens niet
								meer noodzakelijk zijn, worden deze
								geanonimiseerd of verwijderd. Schaats- en
								Skeelerschool In Balans treft adequate
								technische en organisatorische maatregelen om uw
								gegevens te beveiligen. Al onze medewerkers
								hebben een geheimhoudingsverklaring getekend en
								hebben op basis van hun functie wel of geen
								toegang tot onze beveiligde bestanden.
								<b>
									Recht op inzage, correctie of verwijdering
									van persoonsgegevens
								</b>
								U kunt een inzage-, correctie- of
								verwijderingsverzoek richten aan Schaats- en
								Skeelerschool In Balans. Geef duidelijk in het
								onderwerp aan dat het gaat om privacy en meer
								specifiek om een inzage-, correctie- of
								verwijderingsverzoek op grond van artikel 35 of
								36 Wet bescherming persoonsgegevens. Houd er
								rekening mee dat wij in sommige gevallen om
								aanvullende informatie kunnen vragen om uw
								identiteit deugdelijk te kunnen vaststellen.
								<b>Verzet</b>U heeft het recht u te verzetten
								tegen het gebruik van persoonsgegevens. Wanneer
								u aangeeft niet langer (via een bepaald medium)
								te willen worden benaderd, zal Schaats- en
								Skeelerschool In Balans u opnemen in een daartoe
								strekkend bestand en worden uw persoonsgegevens
								niet langer gebruikt voor deze doeleinden. Dit
								kunt u doen via onze administratie. Geef
								duidelijk aan dat het gaat om privacy en meer
								specifiek om een beroep op uw recht op verzet op
								grond van artikel 40 of 41 van de Wet
								bescherming persoonsgegevens. Houd er rekening
								mee dat wij in sommige gevallen om aanvullende
								informatie kunnen vragen om uw identiteit
								deugdelijk te kunnen vaststellen.
								<b>Vragen</b>
								Heeft u na het lezen vragen of opmerkingen over
								ons privacybeleid, dan kunt u deze richten aan:
								Schaats- en Skeelerschool In Balans,
								Terborchlaan 301, 1816 MH Alkmaar of via e-mail:
								info@inbalansalkmaar.nl. Vermeld bij
								correspondentie duidelijk dat het om
								privacybeleid gaat.
								<b>Wijzigingen</b>
								Schaats- en Skeelerschool In Balans behoudt zich
								het recht voor om wijzigingen aan te brengen in
								het privacybeleid. Wij adviseren u dan ook om
								regelmatig op deze pagina te kijken of er
								wijzigingen zijn aangebracht.
							</div>
						</>
					)}
				</article>
			</footer>
		</React.Fragment>
	)
}

export default Footer
