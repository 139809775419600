import React from 'react'
import FeedCardBig from '../FeedCardBig/FeedCardBig'
import './FeedCardHolderBig.scss'
import { Link } from 'gatsby'
import Title from '../Title/Title'

export default (props) => {
	const { cards, color, title, to } = props

	return (
		<article className={'feed-card__wrapper large'}>
			{title && (
				<div className={'feed-card__meta-holder'}>
					<Title heading={2} text={title} />
					{to ? (
						<Link
							className={`link__underline--primary`}
							aria-label={`Link van ${title}`}
							to={to}
						>
							BEKIJK {title}
						</Link>
					) : null}
				</div>
			)}
			{cards && cards.length
				? cards.map((card, i) => {
						const { titles, textContent, date, image } = card

						return (
							<article
								className={`feed-card__holder ${
									color ? color : 'primary'
								}`}
								key={`${card.id}-${i}-${titles[0]}`}
							>
								<FeedCardBig
									title={titles[0]}
									subtitle={titles[1]}
									abstract={textContent[0]}
									description={textContent[1]}
									date={date}
									image={image}
								/>
							</article>
						)
				  })
				: null}
		</article>
	)
}
