import { format } from 'date-fns'
export default (date) => {
	const months = [
		'JAN',
		'FEB',
		'MRT',
		'APR',
		'MEI',
		'JUN',
		'JUL',
		'AUG',
		'SEPT',
		'OKT',
		'NOV',
		'DEC',
	]

	if (!date) {
		return
	}

	const formattedDate = new Date(date)
	const test = format(formattedDate, 'MM/dd')
	const splittedDate = test.split('/')
	const getMonth = months[splittedDate[0] - 1]

	return `${splittedDate[1]} ${getMonth}`
}
