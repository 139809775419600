import React, { useContext } from 'react'
import './HamburgerMenu.scss'
import { animated } from 'react-spring'
import { HeaderContext } from '../../../components/Header/Header'

export default ({ style, addedClass }) => {
	const HeaderCTX = useContext(HeaderContext)
	return (
		<animated.svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 32 24"
			stroke={HeaderCTX.winterTime ? '#005394' : 'rgba(234, 134, 45, 1)'}
			className={`hamburger${addedClass}`}
			style={{ ...style }}
		>
			<g
				fill="currentColor"
				fillRule="evenodd"
				strokeLinecap="square"
				strokeWidth="2"
			>
				<line className={'first-line'} x1="0" y1="1" x2="32" y2="1" />
				<line
					className={'middle-line'}
					x1="0"
					y1="12"
					x2="32"
					y2="12"
				/>
				<line className={'last-line'} x1="14" y1="23" x2="32" y2="23" />
			</g>
		</animated.svg>
	)
}
