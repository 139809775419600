import React, { useState, useEffect, useRef, useContext } from 'react'
import './Header.scss'
import Logo from '../../images/icons/Logo/Logo'
import HamburgerMenu from '../../images/icons/HamburgerMenu/HamburgerMenu'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { useSpring, animated } from 'react-spring'
import { isWinter } from '../../utils/isWinter'
import { useKeyPress } from '../../utils/useKeyPress'
import { SignUpFormContext } from '../SignUpFormModal/SignUpFormModal'
import { format } from 'date-fns'
import { useMediaQuery } from 'react-responsive'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export const HeaderContext = React.createContext({
	currentActiveEventItem: null,
	updateActiveEventItem: () => null,
	winterTime: true,
	winterDatum: null,
	zomerDatum: null,
})

// create the provider
export const HeaderContextProvider = (props) => {
	const [activeEventItem, setActiveEventItem] = useState('alles')
	const { winterDate, zomerDate } = useStaticQuery(graphql`
		query {
			winterDate: wordpressPage(title: { eq: "Winter" }) {
				acf {
					start_datum
				}
			}
			zomerDate: wordpressPage(title: { eq: "Zomer" }) {
				acf {
					start_datum
				}
			}
		}
	`)

	return (
		<HeaderContext.Provider
			value={{
				currentActiveEventItem: activeEventItem,
				updateActiveEventItem: (value) => setActiveEventItem(value),
				winterTime: isWinter(
					winterDate.acf.start_datum,
					zomerDate.acf.start_datum,
				),
				winterDatum: winterDate.acf.start_datum,
				zomerDatum: zomerDate.acf.start_datum,
			}}
		>
			{props.children}
		</HeaderContext.Provider>
	)
}

const config = {
	mass: 2,
	tension: 350,
	friction: 30,
}

export function Header({ links }) {
	const HeaderCTX = useContext(HeaderContext)
	const signUpFormOpen = useContext(SignUpFormContext)
	const winter = HeaderCTX.winterTime
	const [searchValue, setSearchValue] = useState('')
	const isMobile = useMediaQuery({
		query: '(max-width: 48rem)',
	})

	const headerLinks = [
		{
			label: isMobile ? 'Bekijk het aanbod' : 'Aanbod',
			links: '#aanbod',
			filter: 'alles',
		},
		{
			label: 'Partijtjes',
			links: '#aanbod',
			filter: 'partijtjes',
		},
		{
			label: 'Bedrijfsevenementen',
			links: '#aanbod',
			filter: 'bedrijfsevenementen',
		},
		{
			label: 'Scholen',
			links: '#aanbod',
			filter: 'scholen',
		},
	]

	const [hamburgerOpen, setHamburgerOpen] = useState(false)
	const [searchOpen, setSearchOpen] = useState(false)
	const [activeDropdown, toggleActiveDropdown] = useState(false)

	useKeyPress('Escape', () => {
		setHamburgerOpen(false)
		toggleActiveDropdown(false)
		document.documentElement.classList.remove('has-overlay')
	})

	const drawer = useRef()
	const node = useRef()

	function toggleMenu() {
		setHamburgerOpen((value) => !value)
	}
	function toggleSearch() {
		setSearchOpen((value) => !value)
	}

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (node.current.contains(e.target)) {
				// inside click
				return false
			}
			// outside click
			if (hamburgerOpen) toggleMenu()
			toggleActiveDropdown(false)
		}
		if (hamburgerOpen || typeof activeDropdown === 'string') {
			document.addEventListener('mousedown', handleClickOutside)
			document.addEventListener('touchstart', handleClickOutside)
		} else {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}
	}, [])

	const menuTransition = useSpring({
		transform: `translate3d(${hamburgerOpen ? '0%' : '100%'}, 0, 0)`,
		config: config,
		onRest:
			!hamburgerOpen &&
			typeof document !== 'undefined' &&
			document.documentElement.classList.remove('has-overlay'),
	})

	const menuButtonTransition = useSpring({
		stroke: hamburgerOpen
			? '#f2f3f4'
			: HeaderCTX.winterTime
			? '#005394'
			: 'rgba(234, 134, 45, 1)',
		config: config,
	})

	const searchDrawerTransition = useSpring({
		transform: `translateY(${
			searchOpen ? (isMobile ? '4rem' : '2rem') : '-1rem'
		})`,
		zIndex: `${searchOpen ? '1' : '-1'}`,
		pointerEvents: `${searchOpen ? 'auto' : 'none'}`,
		opacity: `${searchOpen ? '1' : '0'}`,
		config: config,
	})

	const headerLinksItems = headerLinks.map((category, i) => {
		if (isMobile && i >= 1) return null
		return (
			<li
				className={'header__nav--container'}
				key={category.label}
				aria-expanded={category.label === activeDropdown}
			>
				<a
					className="link__underline--primary"
					onClick={() => {
						setHamburgerOpen(false)
						category.filter !== HeaderCTX.currentActiveEventItem &&
							HeaderCTX.updateActiveEventItem(category.filter)
						signUpFormOpen &&
							signUpFormOpen.isOpen &&
							signUpFormOpen.stateChangeHandler(false)
					}}
					href={category.links}
				>
					{category.label}
				</a>
			</li>
		)
	})

	return (
		<React.Fragment key="header">
			<header className="header">
				<section className="header__topbar">
					<div className="header__logo">
						<Link to={getLogoLink()} aria-label={'home'}>
							<Logo />
						</Link>
					</div>
					<div className="header__season">
						<small className="header__season--date">
							{winter
								? `${formDate(
										HeaderCTX.winterDatum,
								  )} - ${formDate(HeaderCTX.zomerDatum)}`
								: `${formDate(
										HeaderCTX.zomerDatum,
								  )} - ${formDate(HeaderCTX.winterDatum)}`}
						</small>
						<p className="header__season--name">
							{winter ? 'winter' : 'zomer'}seizoen
						</p>
						<Link
							className="header__season--link link__underline--secondary"
							to={winter ? '/zomer' : '/winter'}
						>
							Bekijk het {winter ? 'zomer' : 'winter'}seizoen
						</Link>
					</div>
				</section>
			</header>
			<nav className="header__nav" role="navigation" ref={node}>
				<ul>{headerLinksItems}</ul>
				<button
					className="header__nav--button"
					onClick={(e) => toggleSearch(e)}
					aria-label={'toggle search'}
					title={'toggle search'}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						height="24"
						viewBox="0 0 24 24"
						width="24"
						fill={
							HeaderCTX.winterTime
								? '#005394'
								: 'rgba(234, 134, 45, 1)'
						}
					>
						<path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
						<path d="M0 0h24v24H0z" fill="none" />
					</svg>
				</button>
				<button
					className="header__nav--button"
					onClick={(e) => toggleMenu(e)}
					aria-label={'toggle menu'}
					title={'toggle menu'}
				>
					<div>
						<span className={hamburgerOpen ? 'open' : ''}>
							menu
						</span>
						<HamburgerMenu
							style={menuButtonTransition}
							addedClass={hamburgerOpen ? ' open' : ''}
						/>
					</div>
				</button>
				<animated.ul
					id="navigation"
					aria-expanded={hamburgerOpen}
					className="header__nav--drawer"
					ref={drawer}
					style={menuTransition}
				>
					{links.length
						? links.map((link) => {
								return (
									<li key={`${link.label}saus`}>
										{!link.to.includes('/') ? (
											<a
												href={link.to}
												className="link__underline--secondary"
												onClick={() => {
													setHamburgerOpen(false)
													signUpFormOpen &&
														signUpFormOpen.isOpen &&
														signUpFormOpen.stateChangeHandler(
															false,
														)
												}}
											>
												{link.label}
											</a>
										) : (
											<Link
												to={link.to}
												className="link__underline--secondary"
												onClick={() => {
													setHamburgerOpen(false)
													signUpFormOpen &&
														signUpFormOpen.isOpen &&
														signUpFormOpen.stateChangeHandler(
															false,
														)
												}}
											>
												{link.label}
											</Link>
										)}
									</li>
								)
						  })
						: null}
				</animated.ul>
				<animated.div
					className={'search__drawer'}
					style={searchDrawerTransition}
					itemScope
					itemType={'https://schema.org/WebSite'}
				>
					<meta
						itemProp="url"
						content="https://www.inbalansalkmaar.nl/"
					/>
					<form
						method={'POST'}
						action={'#'}
						onSubmit={(e) => e.preventDefault()}
						itemProp={'potentialAction'}
						itemScope
						itemType={'https://schema.org/SearchAction'}
					>
						<meta
							itemProp="target"
							content="https://www.inbalansalkmaar.nl/zoekresultaten?q={search_term_string}"
						/>

						<input
							type="search"
							placeholder={'Zoeken'}
							name="search_term_string"
							itemProp="query-input"
							onKeyUp={(e) => setSearchValue(e.target.value)}
							onKeyPress={(e) => {
								if (e.key === 'Enter') {
									setSearchValue(e.target.value)
									trackCustomEvent({
										// string - required - The object that was interacted with (e.g.video)
										category: 'event',
										// string - required - Type of interaction (e.g. 'play')
										action: 'search',
										// string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
										label: e.target.value,
									})
									if (typeof window !== 'undefined') {
										window.location = `/zoekresultaten?q=${e.target.value}`
									}
								}
							}}
						/>
						<Link
							to={`/zoekresultaten?q=${searchValue}`}
							onClick={() => {
								trackCustomEvent({
									// string - required - The object that was interacted with (e.g.video)
									category: 'event',
									// string - required - Type of interaction (e.g. 'play')
									action: 'search',
									// string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
									label: searchValue,
								})
							}}
							state={{ search: searchValue }}
						>
							Zoeken
						</Link>
					</form>
				</animated.div>
			</nav>

			<div className={`gradient${hamburgerOpen ? ' active' : ''}`}></div>
		</React.Fragment>
	)
}

function getLogoLink() {
	if (typeof window !== 'undefined') {
		if (window.location.href.includes('/winter')) {
			return '/winter'
		} else if (
			window.location.href.includes('/zomer') ||
			(window.location.href.includes('mountainbike') &&
				!window.location.href.includes('schaats') &&
				!window.location.href.includes('#mountainbike')) ||
			(window.location.href.includes('skeeler') &&
				!window.location.href.includes('schaats') &&
				!window.location.href.includes('#skeeler'))
		) {
			return '/zomer'
		} else {
			return '/'
		}
	}
}

function formDate(date) {
	return format(new Date(date), 'dd/MM')
}
