import React, { useContext } from 'react'
import './Title.scss'
import Wave from '../../images/icons/Wave'
import { HeaderContext } from '../Header/Header'

export default ({ heading, text, className, wave }) => {
	const HeadingElement = heading ? `h${heading}` : 'p'
	const HeaderCTX = useContext(HeaderContext)

	return (
		<React.Fragment>
			<HeadingElement
				className={`title${className ? ` ${className}` : ''}`}
			>
				{text}
				{wave ? <Wave winter={HeaderCTX.winterTime} /> : null}
			</HeadingElement>
		</React.Fragment>
	)
}
