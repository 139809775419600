import React from 'react'

export default ({ color }) => (
	<svg
		className={'caret'}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 13 13"
	>
		<path
			fill={color ? color : '#F2F3F4'}
			fillRule="evenodd"
			d="M6.5 13L0 0h13z"
		/>
	</svg>
)
