import React from 'react'

export default ({ winter, color }) => (
	<svg
		viewBox="0 0 129 15"
		width="129"
		height="15"
		xmlns="http://www.w3.org/2000/svg"
		className="wave"
		aria-hidden="true"
		role="presentation"
	>
		<g fill="none" fillRule="evenodd">
			<path
				d="M2 13C12.42 13 12.42 2 22.83 2c10.42 0 10.42 11 20.84 11C54.08 13 54.08 2 64.5 2c10.42 0 10.42 11 20.83 11 10.42 0 10.42-11 20.84-11 10.41 0 10.41 11 20.83 11"
				stroke={
					color ? color : winter ? '#005394' : 'rgba(234, 134, 45, 1)'
				}
				strokeLinecap="square"
				strokeWidth="3"
			/>
		</g>
	</svg>
)
