import React from 'react'
import Img from 'gatsby-image'
import './FeedCardBig.scss'
import formatDateToDayMonth from '../../utils/formatDateToDayMonth'
import ReactHtmlParser from 'react-html-parser'

export default (props) => {
	const { title, subtitle, abstract, description, date, image } = props
	const formattedDate = formatDateToDayMonth(date)

	let transformedDescription = description
	if (description && Array.isArray(description)) {
		transformedDescription = description.map((d) => {
			if (typeof d !== 'string') return d
			// Regex from: https://www.codegrepper.com/code-examples/javascript/js+replace+text+link+with+anchor+tags
			return d.replace(
				/(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi, // eslint-disable-line
				'<a href="$1" target="_blank">$1</a>',
			)
		})
	}
	return (
		<div className={'feed-card'}>
			{image && image?.localFile?.childImageSharp ? (
				<Img
					fluid={image.localFile.childImageSharp.fluid}
					className={'feed-card__image'}
					alt={image.alt_text}
				/>
			) : (
				<div className={'feed-card__imgfiller'}></div>
			)}

			<div>
				{date ? <time dateTime={date}>{formattedDate}</time> : null}
				<div
					className={`feed-card__container ${
						abstract || description ? '' : ' single-title'
					}`}
				>
					<React.Fragment>
						{title && (
							<h3 className={`feed-card__title`}>{title}</h3>
						)}

						{abstract && (
							<div className={'feed-card__abstract'}>
								{abstract}
							</div>
						)}
						{subtitle && (
							<h3 className={`feed-card__subtitle`}>
								{subtitle}
							</h3>
						)}
						{transformedDescription && (
							<div className={'feed-card__description'}>
								{typeof transformedDescription[0] === 'string'
									? ReactHtmlParser(transformedDescription)
									: transformedDescription}
							</div>
						)}
					</React.Fragment>
				</div>
			</div>
		</div>
	)
}
