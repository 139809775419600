import React from 'react'
import './FeedCardHolder.scss'
import FeedCard from '../FeedCard/FeedCard'
import { Link } from 'gatsby'
import Title from '../Title/Title'

export default ({ data }) => {
	const { cards, color, title, to } = data
	return (
		<article className={'feed-card__wrapper'}>
			<div className={'feed-card__meta-holder'}>
				<Title heading={2} text={title} />
				{to ? (
					<Link
						className={`link__underline--primary`}
						aria-label={`Link van ${title}`}
						to={to}
					>
						BEKIJK {title}
					</Link>
				) : null}
				{title === 'Twitter' ? (
					<a
						className={`link__underline--primary`}
						title="InBalans Twitter"
						aria-label="InBalans Twitter"
						href="https://twitter.com/inbalansalkmaar"
						rel="noopener noreferrer"
						target="_blank"
					>
						BEKIJK {title}
					</a>
				) : null}
			</div>
			<ul className={`feed-card__holder ${color}`}>
				{cards.length
					? cards.map((card, i) => (
							<FeedCard
								key={`${card.id} - ${i}`}
								data={card}
								twitter={title === 'Twitter'}
							/>
					  ))
					: null}
			</ul>
		</article>
	)
}
