import React from 'react'
import { Link } from 'gatsby'
import './Button.scss'

export default ({
	text,
	className,
	to,
	action,
	role,
	ariaSelected,
	ariaControls,
	ariaDisabled,
	idValue,
	type,
}) => {
	return typeof to !== 'undefined' ? (
		to.includes('http') || to.includes('mailto') || to.includes('tel') ? (
			<a
				className={`button ${className ? className : 'primary'}`}
				href={to}
				onClick={action}
				role={role ? role : null}
				aria-selected={ariaSelected ? ariaSelected : null}
				aria-controls={ariaControls ? ariaControls : null}
				aria-disabled={ariaDisabled ? ariaDisabled : null}
				id={idValue ? idValue : undefined}
				title={text}
				aria-label={text}
			>
				{text}
			</a>
		) : (
			<Link
				className={`button ${className ? className : 'primary'}`}
				to={to}
				onClick={action}
				role={role ? role : null}
				aria-selected={ariaSelected ? ariaSelected : null}
				aria-controls={ariaControls ? ariaControls : null}
				aria-disabled={ariaDisabled ? ariaDisabled : null}
				id={idValue ? idValue : undefined}
				title={text}
				aria-label={text}
			>
				{text}
			</Link>
		)
	) : (
		<button
			className={`button ${className ? className : 'primary'}`}
			onClick={action}
			role={role ? role : null}
			aria-selected={ariaSelected ? ariaSelected : null}
			aria-controls={ariaControls ? ariaControls : null}
			aria-disabled={ariaDisabled ? ariaDisabled : null}
			id={idValue ? idValue : undefined}
			type={type ? type : 'button'}
			title={text}
			aria-label={text}
		>
			{text}
		</button>
	)
}
