import React from 'react'
import { Link } from 'gatsby'

export default ({ title, links }) => {
	return (
		<div>
			<h4 className={'title'}>{title}</h4>

			<ul className={'biglist'}>
				{links.map((link) => (
					<li key={link.name}>
						<Link
							key={link.name}
							className={'link'}
							to={link.route}
						>
							{link.name}
						</Link>
					</li>
				))}
			</ul>
		</div>
	)
}
