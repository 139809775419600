import React, { useContext } from 'react'
import { HeaderContext } from '../../../components/Header/Header'

export default ({ color }) => {
	const HeaderCTX = useContext(HeaderContext)

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239 377">
			<path
				fill={
					color
						? color
						: HeaderCTX.winterTime
						? '#EA862D'
						: 'rgba(0, 83, 148, 1)'
				}
				fillRule="evenodd"
				d="M112.35 113.11C112.35 80.95 9.46-5.9 2.12 1.34c-10.57 10.43 49.31 58.72 60.95 70.2C83.77 92 94.6 99.25 94.6 128.38v95.2c0 7.05-6.4 98.11 14.35 79.96 10.15-8.89 1.45-89.62 1.45-103.18 0-16.3 2.08-42.43 1.95-87.24m18.19 63.18c0-32.23 87.72-112.02 106.35-104.94 13.85 5.26-48.95 58.85-60.5 70.37-20.54 20.5-28.34 18.95-28.83 56.64-.42 31.8-4.04 61.23.09 92.78 2.55 19.52 6.8 101.42-16.79 83.12-10.63-8.25-1.15-69.05 0-100.25 1.88-50.83-1.17-80.49-.32-97.71"
			/>
		</svg>
	)
}
