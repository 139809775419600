import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import FeedCardHolderBig from '../FeedCardHolderBig/FeedCardHolderBig'
import FeedCardHolder from '../FeedCardHolder/FeedCardHolder'
import Row from '../Row/Row'
import { isAfter } from 'date-fns'

export function isBeforeLastCalendarDate(item) {
	const date = new Date(item.date)
	return isAfter(date, Date.now())
}

const SocialComponent = () => {
	const {
		allWordpressWpKalender,
		allWordpressWpNieuws,
		// allTwitterStatusesUserTimelineInbalansalkmaar,
	} = useStaticQuery(
		graphql`
			query {
				allWordpressWpKalender(
					sort: { fields: acf___date, order: DESC }
				) {
					nodes {
						id
						acf {
							title
							abstract
							description
							subtitle
							date
						}
					}
				}
				allWordpressWpNieuws(
					limit: 5
					sort: { fields: date, order: DESC }
				) {
					nodes {
						acf {
							description
							title
							date
						}
						id
					}
				}
			}
		`,
	)

	// allTwitterStatusesUserTimelineInbalansalkmaar(
	// 	sort: { fields: created_at, order: DESC }
	// ) {
	// 	nodes {
	// 		user {
	// 			screen_name
	// 		}
	// 		id
	// 		full_text
	// 		entities {
	// 			urls {
	// 				url
	// 			}
	// 		}
	// 	}
	// }

	const feedCardHolder = {
		title: 'Kalender',
		to: '/kalender',
		color: 'primary',
		cards: allWordpressWpKalender.nodes
			.map((event, i) => ({
				date: event.acf.date,
				titles: [event.acf.title, event.acf.subtitle],
				textContent: [event.acf.abstract, event.acf.description],
			}))
			.sort((a, b) => new Date(b.date) - new Date(a.date))
			.filter((item) => isBeforeLastCalendarDate(item))
			.sort((a, b) => new Date(a.date) - new Date(b.date)),
	}

	const newsFeedData = {
		title: 'Nieuws',
		to: '/nieuws',
		color: 'primary',
		cards: allWordpressWpNieuws.nodes.map((news) => {
			return ({
				titles: [news.acf.title],
				date: news.acf.date,
				id: news.id,
			})
		}),
	}

	// const twitter = {
	// 	title: 'Twitter',
	// 	color: 'secondary',
	// 	cards: allTwitterStatusesUserTimelineInbalansalkmaar.nodes.map(
	// 		(tweet) => {
	// 			let tweetText = tweet.full_text
	// 			tweet.entities.urls.forEach((urlObj) => {
	// 				if (
	// 					urlObj &&
	// 					urlObj.url &&
	// 					tweetText.includes(urlObj.url)
	// 				) {
	// 					tweetText = tweetText.replace(
	// 						urlObj.url,
	// 						`<a href="${urlObj.url}">${urlObj.url}</a>`,
	// 					)
	// 				}
	// 			})
	// 			return {
	// 				titles: [tweet.user.screen_name],
	// 				textContent: [tweetText],
	// 				id: tweet.id,
	// 			}
	// 		},
	// 	),
	// }
	feedCardHolder.cards.length = 5
	return (
		<>
			<FeedCardHolderBig data={feedCardHolder} />
			<FeedCardHolder data={feedCardHolder} />

			<Row>
				<FeedCardHolder data={newsFeedData} />
				{/* <FeedCardHolder data={twitter} /> */}
			</Row>
		</>
	)
}

export default SocialComponent
